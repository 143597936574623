<template>
  <div class="page-content" v-if="milestone">
    <page-breadcrumb class="mb-2" :title="`Milestone ${ milestone.id }`" :items="breadcrumbItems" />

    <section class="mb-2">
      <b-card>
        <form-generator :model="milestone" :key="milestone.updatedAt" :schema="milestone_form_schema" />
      </b-card>
    </section>

    <h3>Stages</h3>
    <data-table-ssr id="stage_list" ref="stage_list" :limit-base="18"
      :get-list-fn="getStageList" :columns="stage_fields"
      :create_schema="create_stage_schema" :create-row-fn="createStage"
      :to-edit-fn="toEditStage" :delete-row-fn="deleteStage"
      :more_actions="[{ text: 'Create one above', icon: 'PlusCircleIcon', handler: createStageAbove, }]"
    />

    <h3 class="mt-3">Words</h3>
    <word-list :word-ids="milestone.words" :milestone-id="milestone_id" />
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteMilestone"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import { removeEmpty, buildSelectOptions } from '@core/utils/index'

import service from '../service'
import stageService from '../../stage/service'
import { STAGE_TYPES, STATUS, VOCAB_TYPES } from '../../stage/constant'

const STAGE_TYPE_OPTIONS = buildSelectOptions(STAGE_TYPES);
const GAME_TYPE_OPTIONS = buildSelectOptions(VOCAB_TYPES)
const PUBLISH_OPTIONS = buildSelectOptions(STATUS);

import WordList from './_components/WordList.vue'

const stage_fields = [
  { label: 'Name', display: (row, { rows }) => `${row.milestone}.${row.stage_number - rows[0].stage_number + 1}`, field: 'map_title' },
  { label: 'Number', field: 'stage_number' },
  { label: 'Progress mark', field: 'progress_mark' },
  { label: 'Publish status', field: 'is_published', input_type: 'select', options: PUBLISH_OPTIONS },
  { label: 'Type', field: 'type', input_type: 'select', options: STAGE_TYPE_OPTIONS },
];

const milestone_form_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Name', field: 'name', input_type: 'text', validate: { required: true } },
      { label: 'Subtitle', field: 'subtitle', input_type: 'text' },
      { label: 'Content', field: 'content', input_type: 'ui-component' },
    ]
  }
];

const create_stage_schema = [
  {
    cols: 12,
    fields: [
      { field: 'type', label: 'Type', input_type: 'select', options: STAGE_TYPE_OPTIONS },
      {
        field: 'game_type',
        label: 'Vocab Type',
        input_type: 'select',
        options: GAME_TYPE_OPTIONS,
        displayCond(model) { return model.stage_type === STAGE_TYPES.VOCAB }
      },
    ]
  }
];

export default {
  components:{
    WordList,
  },
  data(){
    return{
      stage_fields, milestone_form_schema, create_stage_schema,
      milestone: null,
      isUpdating: false,
      isDeleting: false,
    }
  },
  computed: {
    breadcrumbItems() {
      let items = [
        {
          text: 'Levels list',
          to: { name: 'stage_level-list' },
        },
        {
          text: 'Level',
          to: { name: 'stage_level-detail', params: { id: this.milestone.level_id } },
        },
        {
          text: `Milestone ${this.milestone.id}`, active: true
        },
      ]
      return items
    },
    milestone_id() {
      return this.$route.params.milestone_id;
    },
  },
  created(){
    this.getMilestone()
  },
  methods:{
    async getMilestone(){
      let milestone = await service.get({ id: this.milestone_id });
      this.milestone = milestone;
    },
    async getStageList({ limit, page, query }) {
      query = removeEmpty(query);
      query.milestone = this.milestone.id;

      let data = await stageService.getList({
        query: encodeURIComponent(JSON.stringify(query)),
        page,
        limit,
      });
      let list = data.list || []; let total = data.total || 0;
      return { list, total }
    },
    async createStage(data) {
      let new_data = await stageService.create({ ...data, milestone: this.milestone.id });
      return new_data;
    },
    createStageAbove({ item }) {
      this.$refs.stage_list.openCreatePopup({ display_order: item.display_order - 1 });
    },
    async deleteStage({ _id }) {
      await stageService.delete({ id: _id });
    },
    toEditStage({ _id }) {
      this.$router.push({ name: 'arena-stage-detail', params: { stage_id: _id } });
    },
    async update() {
      this.isUpdating = true;
      const response = await service.update({
        data: JSON.stringify(this.milestone),
      });
      if (response.data.statusCode === 200) {
        this.$store.dispatch("pushSuccessNotify", {
          text: "Milestone updated!",
        });
      }
      this.getMilestone();
      this.isUpdating = false;
    },
    async deleteMilestone() {
      this.isDeleting = true;
      const response = await service.delete({ id: this.milestone_id });
      if (response.data.statusCode === 200) {
        this.$store.dispatch("pushSuccessNotify", {
          text: "Milestone deleted!",
        });
      }
      this.isDeleting = false;
      this.$router.push({ name: "arena-milestone-list" });
    },
  }
}
</script>
